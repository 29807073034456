import React from 'react'

function ErrorPage() {
  return (
    <div style={{ minHeight: "500px"}}>
        <h1 style={{ paddingTop:"150px"}}>Error 404 </h1>
        <h2 >page not found</h2>
    </div>
  )
}

export default ErrorPage