import './App.css';


import AllRouters from './components/AllRouters';

function App() {
  return (
    <div className="App">
     


  <AllRouters/>
 
   
    </div>
  );
}

export default App;
